import axios from "axios";
import { api } from "../../api";
import { MainCategoryTableColumnData,SubCategoryTableColumnData, marketSolutions } from "../../Types/TableType";
// Type for Authorization Token
export const getUserToken = (): string | null => {
    const tokenStr = sessionStorage.getItem("userToken");
    return tokenStr ? `${tokenStr}` : null;
};


interface MarketSolutionResponse {
  data: any; // Adjust this based on the actual response structure
}
//-------------------------------Main Category API's--------------------------------//
export const fetchMarketAndSolutionsCategory = async (): Promise<MarketSolutionResponse> => {
  try {
    const response = await axios.get(`${api}/solutions/SolutionRetrieve`, {
      headers: {  Authorization: `Bearer ${getUserToken()}` },
    });
    return response.data; // Return the whole data object
  } catch (error) {
    throw new Error("Failed to fetch MainCategory.");
  }
};

export const fetchMarketAndSolutionsCategoryByCode = async (category_code :string): Promise<MarketSolutionResponse> => {
  try {
    const response = await axios.get(`${api}/solutions/SolutionRetrieve?sub_category_code=${category_code}`, {
      headers: {  Authorization: `Bearer ${getUserToken()}` },
    });
    return response.data; // Return the whole data object
  } catch (error) {
    throw new Error("Failed to fetch MainCategory.");
  }
};


// MainCategoryApi.tsx
export const createMarketAndSolutionsCategory = async (formData: FormData): Promise<MarketSolutionResponse> => { 
  try {
    console.log("formData", formData);
    const response = await axios.post(`${api}/solutions/SolutionCreation`, formData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'multipart/form-data', // Correct header for file uploads
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to create MainCategory.");
  }
};

export const updateMarketAndSolutionsCategory = async (formData: FormData): Promise<MarketSolutionResponse> => {
  try {
      const response = await axios.put(`${api}/solutions/SolutionUpdate`, formData, {
          headers: {
              Authorization: `Bearer ${getUserToken()}`,
              'Content-Type': 'multipart/form-data',
          },
      });
      return response.data;
  } catch (error) {
      throw new Error("Failed to update MainCategory.");
  }
};

export const deleteDataListSolutionsCategory = async (formData: any): Promise<MarketSolutionResponse> => {
  try {
    console.log(formData, '111');
    const response = await axios.delete(`${api}/solutions/remove-item`, {
      data: formData,  // Send formData as part of the request body
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to update MainCategory.");
  }
};


export const deleteMarketAndSolutionsCategory = async (category_code: string): Promise<MarketSolutionResponse> => {
  try {
    const response = await axios.delete(`${api}/solutions/SolutionDelete?market_solution_code=${category_code}`, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to delete MainCategory.");
  }
};

