import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row,FormGroup,Label } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from '../../../../../redux-toolkit/store';
import { H3 } from '../../../../../AbstractElements';
import { fetchData, deleteTestimonialData, updateTestimonialData } from '../../../../../redux-toolkit/reducers/TestimonialReducer';
import { fetchMainCategoryData,deleteMainCategoryData,createMainCategoryData,updateMainCategoryData } from '../../../../../redux-toolkit/reducers/marketSolutionsReducer';
import { fetchSubCategoryData,deleteSubCategoryData,createSubCategoryData,updateSubCategoryData } from '../../../../../redux-toolkit/reducers/subCategoryReducer';
import { fetchMarketDataByCode, fetchMarketData,updateMarketsData,deleteDataMarketsData } from '../../../../../redux-toolkit/reducers/MarketReducer';
import { TestimonialTableColumnData, MainCategoryTableColumnData, SubCategoryTableColumnData,marketSolutions, storyList,dataList } from '../../../../../Types/TableType';
import { FaEdit, FaTrash,FaEye } from 'react-icons/fa'; // Font Awesome icons
import MyAzureBlobComponent from '../../../../azure/azure';
import styles from "../../MarketAndSolutionCreate/createNewMarketAndSolutionsForm/create.module.css"
import { FiPlus } from 'react-icons/fi';
// import { Select } from '../../../../../Utils/Constants';
import Select from 'react-select';
import { Editor } from "primereact/editor"; // PrimeReact Editor
import 'quill/dist/quill.snow.css';
import { toast } from 'react-toastify';

interface MainCategoryTableColumn{
    category_code:string,
    category_name:string,
    category_image:string,
    category_description:string,
    sub_description:string,
    Description:string,
    category_icon:string,
}

export default function RowCreateCallback() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalMainOpen, setIsModalMainOpen] = useState(false);
    const [isModalViewOpen, setIsModalViewOpen] = useState(false);
    const [confirmDeleteMain, setConfirmDeleteMain] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedTestimonial, setSelectedTestimonial] = useState<TestimonialTableColumnData | null>(null);
    const [selectedSubCategory, setselectedSubCategory] = useState<SubCategoryTableColumnData | null>(null);
    const [selectedMainCategory, setselectedMainCategory] = useState<MainCategoryTableColumn | null>(null);
    const [deleteId, setDeleteId] = useState<string | null>(null);
    const [marketData, setMarketData] = useState<marketSolutions>({
        market_solution_code:'',
    main_category_code:'',
    main_category_name:'',
    sub_category_code:'',
    sub_category_name:'',
    description: '',
    // banner_image:'',
    left_image:'',
    right_image:'',
    story_list:[],
    data_list:[],
    other_list:[]
    });
    console.log("marketData",marketData)
    // const [bannerImagePreview, setBannerImagePreview] = useState<string | null>(null);
    // const [bannerImage, setBannerImage] = useState<File | null>(null);
    const [subImage, setSubImage] = useState<File | null>(null);
    const [leftImage, setLeftImage] = useState<string | null>(null);
    const [leftNewImage, setLeftNewImage] = useState<File | null>(null);
    const [rightImage, setRightImage] = useState<string | null>(null);
    const [rightNewImage, setRightNewImage] = useState<File | null>(null);
    
    const [marketViewData, setMarketViewData] = useState<any[]>([]);
    const [editData, setEditData] = useState<TestimonialTableColumnData>({
        testimonial_code: '',
        testimonial_auth_name: '',
        testimonial_auth_designation: '',
        testimonial_description: '',
        testimonial_auth_image: '',
    });
    const [editSub, setEditSub] = useState<SubCategoryTableColumnData>({
        sub_category_code:'',
        main_category_code:'',
        sub_category_image:'',
        sub_category_description:'',
        sub_category_name:'',
        
    });
    console.log("editSub",editSub)
    const [editMain, setEditMain] = useState<MainCategoryTableColumn>({
        category_code:'',
        category_image:'',
        category_description:'',
        category_name:'',
        category_icon:'',
        sub_description:'',
        Description :''
    });
    const [expandedCategory, setExpandedCategory] = useState(null);

    const handleToggle = (categoryId:any) => {
        setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
    };
    const [story_list, setStories] = useState<
    Array<{story_description: string }>
  >([{story_description: "" }]);

  console.log("story_list",story_list)
  const [data_list, setData] = useState<
    Array<{ data_title: string; data_description: string; _id : string }>
  >([{ data_title: "", data_description: "", _id: "" }]);
  const [other_list, setOtherList] = useState<
    Array<{ other_title: string; other_description: string }>
  >([{ other_title: "", other_description: "" }]);
  console.log("data_list",data_list)
    const dispatch: AppDispatch = useDispatch();


    const testimonail = useSelector((state: RootState) => state.Testimonial);
    const MainCategory = useSelector((state: RootState) => state.MainCategory);
    const Markets = useSelector((state: RootState) => state.Markets);
    const MarketsDataInfo: marketSolutions[] = Array.isArray(Markets?.data)
        ? Markets.data
        : Object.values(Markets?.data || {});
    const MainCategoryDataInfo: MainCategoryTableColumnData[] = Array.isArray(MainCategory?.data)
        ? MainCategory.data
        : Object.values(MainCategory?.data || {});
    const testimonailData: TestimonialTableColumnData[] = Array.isArray(testimonail?.data)
        ? testimonail.data
        : Object.values(testimonail?.data || {});
    // console.log("MarketsDataInfo",MarketsDataInfo)
    useEffect(() => {
        const fetchMilestones = async () => {
            try {
                await dispatch(fetchData());
                await dispatch(fetchMainCategoryData());
                await dispatch(fetchMarketData());
            } catch (error) {
                console.error("Error fetching testimonial:", error);
            }
        };

        fetchMilestones();
    }, [dispatch]);

    const handleSubCategoryImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setSubImage(e.target.files[0]);
        }
    };

    // const handleBannerImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     if (e.target.files && e.target.files.length > 0) {
    //         const file = e.target.files[0];
    //         setBannerImage(e.target.files[0]);
    //         setBannerImagePreview(URL.createObjectURL(file)); // Preview the image
    //     }
    // };
    const handleLeftImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setLeftNewImage(e.target.files[0])
            setLeftImage(URL.createObjectURL(file)); // Preview the image
        }
    };
    const handleRightImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setRightNewImage(e.target.files[0])
            setRightImage(URL.createObjectURL(file)); // Preview the image
        }
    };

    const addStory = () => {
        setStories([...story_list, { story_description: "" }]);
      };
    
      const removeStory = (index: number) => { 
        const updatedStories = story_list.filter((_, i) => i !== index);
        setStories(updatedStories);
    };
    
      const handleStoryInputChange = (
        index: number,
        field: "story_description",
        value: string
    ) => {
        setStories((prevStories) => {
            const updatedStories = prevStories.map((story, idx) => {
                if (idx === index) {
                    return { ...story, [field]: value };
                }
                return story;
            });
            return updatedStories;
        });
    };
    
      const addStoryData = () => {
        setData([...data_list, { data_title: "", data_description: "", _id:"" }]);
      };
    
      const removeStoryData = async(index: number, _id: string, data_title:string, data_description: string) => {
        const updatedData = data_list.filter((_, i) => i !== index);
        const formData = {
            market_solution_code: marketData.market_solution_code,
            data_title:data_title,
            data_description:data_description
        }
        await dispatch(deleteDataMarketsData(formData))
        setData(updatedData);
      };

      const addOtherListData = () => {
        setOtherList([...other_list, { other_title: "", other_description: "" }]);
      };

      const removeOtherListData = (index: number) => {
        const updatedData = other_list.filter((_, i) => i !== index);
        setOtherList(updatedData);
      };
    
      const handleStoryDataInputChange = (
        index: number,
        field: "data_title" | "data_description",
        value: string
      ) => {
        setData((prevData) => {
          const updatedData = prevData.map((story, idx) => {
            if (idx === index) {
              return { ...story, [field]: value };
            }
            return story;
          });
          return updatedData;
        });
      };
      const handleOtherListDataInputChange = (
        index: number,
        field: "other_title" | "other_description",
        value: string
      ) => {
        setOtherList((prevData) => {
          const updatedData = prevData.map((story, idx) => {
            if (idx === index) {
              return { ...story, [field]: value };
            }
            return story;
          });
          return updatedData;
        });
      };

      const handleEdit = async(row: SubCategoryTableColumnData) => {
        setselectedSubCategory(row);
        setEditSub(row);
        setIsModalOpen(true);
    
        try {
            const response = await dispatch(fetchMarketDataByCode(row.sub_category_code)) as any;
            const fetchedData = response.payload.data;
            setMarketViewData(fetchedData);
    
            // Set stories state directly
            const initialStories = fetchedData[0]?.story_list || [];
            const initialData = fetchedData[0]?.data_list || [];
            const initialOtherListData =fetchedData[0]?.other_list || [];
            setStories(initialStories);
            setData(initialData);
            setOtherList(initialOtherListData);
    
            // Update market data, using fetched data
            setMarketData({
                market_solution_code: fetchedData[0]?.market_solution_code || '',
                main_category_code: fetchedData[0]?.main_category_code || '',
                main_category_name: fetchedData[0]?.main_category_name || '',
                sub_category_code: fetchedData[0]?.sub_category_code || '',
                sub_category_name: fetchedData[0]?.sub_category_name || '',
                description: fetchedData[0]?.description || '',
                // banner_image: fetchedData[0]?.banner_image || '',
                left_image: fetchedData[0]?.left_image || '',
                right_image: fetchedData[0]?.right_image || '',
                story_list: initialStories,
                data_list: initialData,
                other_list: initialOtherListData,
            });
        } catch (error) {
            console.error("Error fetching market data:", error);
        }
    };

    const handleView =async(row: SubCategoryTableColumnData)=>{
        setselectedSubCategory(row);
        setIsModalViewOpen(true);
        try {
            const response = await dispatch(fetchMarketDataByCode(row.sub_category_code)) as any;
            const fetchedData = response.payload.data;
            setMarketViewData(fetchedData)
            setMarketData(fetchedData); // Store the `data` array in state
            // console.log('11111111',fetchedData);
        } catch (error) {
            console.error("Error fetching market data:", error);
        }

    }

    const handleDelete = (row: SubCategoryTableColumnData) => {
        setDeleteId(row.sub_category_code);
        setConfirmDelete(true);
    };

    const confirmDeleteHandler = (confirmed: boolean) => {
        if (confirmed && deleteId) {
            dispatch(deleteSubCategoryData(deleteId))
            .unwrap()
            .then(() => {
                setDeleteId(null);
                setConfirmDelete(false);
                dispatch(fetchMainCategoryData());
            })
            .catch((error) => {
                console.error("Error deleting sub category:", error);
                setDeleteId(null);
                setConfirmDelete(false);
            });
        }
        setConfirmDelete(false);
    };

    const handleEditMain = (row: MainCategoryTableColumnData) => {
        setselectedMainCategory(row);
        setEditMain({
            category_code: row.category_code,
            category_image: row.category_image,
            category_description: row.category_description,
            category_name: row.category_name,
            category_icon: row.category_icon,
            sub_description: row.sub_description,
            Description: row.Description,
        });
        setIsModalMainOpen(true);
    };

    const handleDeleteMain = (row: MainCategoryTableColumnData) => {
        setDeleteId(row.category_code);
        setConfirmDeleteMain(true);
    };

    const confirmDeleteHandlerMain = (confirmed: boolean) => {
        if (confirmed && deleteId) {
            dispatch(deleteMainCategoryData(deleteId))
            .then(()=>{
                setDeleteId(null);
                setConfirmDeleteMain(false);
                dispatch(fetchMainCategoryData());
            })
            .catch((error) => {
                setDeleteId(null);
                setConfirmDeleteMain(false);
                console.error("Error deleting main category:", error);
            });
        }
        setConfirmDeleteMain(false);
    };
    const handleFileChangePopup = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setEditSub((prevData: any) => ({
                ...prevData,
                sub_category_image: file,
            }));
        }
    };
    const handleInputChange = (field: keyof SubCategoryTableColumnData, value: string) => {
        setEditSub((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        
    };
    const handleInputChangeNew = (field: keyof marketSolutions, value: string) => {
        setMarketData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };
    const handleFileChangePopupMain = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setEditMain((prevData: any) => ({
                ...prevData,
                category_image: file,
            }));
        }
    };
    const handleFileChangeIconPopup = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                setEditMain((prevData: any) => ({
                    ...prevData,
                    category_icon: file,
                }));
            }
        };
    const handleInputChangeMain = (field: keyof MainCategoryTableColumn, value: string) => {
        setEditMain((prevData) => ({
            ...prevData,
            [field]: value,
            category_code: prevData.category_code || '',
            category_image: prevData.category_image || '',
            category_name: prevData.category_name || '',
            category_description: prevData.category_description || '',
            sub_description: prevData.sub_description || '',
            category_icon: prevData.category_icon || '',
            Description: prevData.Description || '',
        }));
    };
    const handleSave = () => {
        setMarketData((prev) => ({ ...prev, story_list: story_list }));
        setMarketData((prev) => ({ ...prev, data_list:data_list}));
        setMarketData((prev) => ({ ...prev, other_list:other_list}));

        const formData = new FormData();
        formData.append('market_solution_code', marketData.market_solution_code);
        formData.append('sub_category_name', editSub.sub_category_name);
        formData.append('sub_category_code', editSub.sub_category_code)
        formData.append('sub_category_image', editSub.sub_category_image);
        formData.append('sub_category_description', editSub.sub_category_description);
        formData.append('main_category_code',editSub.main_category_code);
        if(subImage){
            formData.append('sub_category_image', subImage);
        }
        formData.append('description',marketData.description);
        // if(bannerImage){
        //     formData.append('banner_image',bannerImage)
        // }
        if(leftNewImage){
            formData.append('left_image', leftNewImage);
        }
        formData.append('story_list',JSON.stringify(story_list))
        if(rightNewImage){
            formData.append('right_image', rightNewImage);
        }
        formData.append('data_list',JSON.stringify(data_list))
        formData.append('other_list', JSON.stringify(other_list))
        dispatch(updateMarketsData(formData))
        .then((response) => {
            setEditSub({ ...editSub, sub_category_name: '', sub_category_image: '', sub_category_description: '',main_category_code:'' });
            setMarketData({
                ...marketData,
                market_solution_code:'',
                main_category_code:'',
                main_category_name:'',
                sub_category_code:'',
                sub_category_name:'',
                description: '',
                // banner_image: '',
                left_image: '',
                right_image: '',
                story_list: [],
                data_list: [],
                other_list:[],
            })
            setIsModalOpen(false);
        })
        .catch((error) => {
            console.error("Error in Update",error);
        });
    };
    const handleEditMainCategory = async() => {
            const formData = new FormData();
            formData.append('category_name', editMain.category_name);
            formData.append('category_image', editMain.category_image);
            formData.append('category_icon', editMain.category_icon);
            formData.append('sub_description',editMain.sub_description);
            formData.append('Description',editMain.Description);
            await dispatch(updateMainCategoryData(formData))
            .then(() => {
                toast.success(`Updated Successfully`);
                setEditMain({category_code:'',
                    category_image:'',
                    category_description:'',
                    category_name:'',
                    category_icon:'',
                    sub_description:'',
                    Description :''})
                setIsModalMainOpen(false)
                dispatch(fetchMainCategoryData())
            })
            .catch((error) => {
                console.error("Failed to create Main Category:", error);
            });
            setIsModalMainOpen(false)
    };
    return (
        <Col sm={12}>
            <Row>
            {MainCategoryDataInfo.map((category) => {
                const isExpanded = expandedCategory === category._id;
                const subCategoriesToShow = isExpanded ? category.sub_category_data : category.sub_category_data.slice(0, 5);

                return (
                    <Col xxl={4} lg={4} md={6} key={category._id}>
                        <Card className="project-box">
                            <CardBody>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h3>{category.category_name}</h3>
                                    <div>
                                        <FaEdit
                                            style={{ cursor: 'pointer', marginRight: '10px' }}
                                            onClick={() => handleEditMain(category)}
                                        />
                                        <FaTrash
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleDeleteMain(category)}
                                        />
                                    </div>
                                </div>
                                <div className="contact-info">
                                    {subCategoriesToShow.map((subCategory) => (
                                        <p key={subCategory._id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            {subCategory.sub_category_name}
                                            <span>
                                                <FaEye
                                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                                    onClick={() => handleView(subCategory)}
                                                />
                                                <FaEdit
                                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                                    onClick={() => handleEdit(subCategory)}
                                                />
                                                <FaTrash
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleDelete(subCategory)}
                                                />
                                            </span>
                                        </p>
                                    ))}
                                    {category.sub_category_data.length > 5 && (
                                        <p
                                            onClick={() => handleToggle(category._id)}
                                            style={{ marginTop: '10px', cursor: 'pointer', color: 'blue' }}
                                        >
                                            {isExpanded ? 'Show Less' : 'Show More'}
                                        </p>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                );
            })}
        </Row>

            {/* Edit Modal for SubCategory */}
            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)} size="xl" style={{ maxWidth: '90%', width: '100%' }}>
    <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Item</ModalHeader>
    <ModalBody>
        <Row>
            <Col md="6">
                <FormGroup>
                    <Label for="newName">Name</Label>
                    <input
                        type="text"
                        className="form-control"
                        value={editSub?.sub_category_name || ''}
                        onChange={(e) => handleInputChange('sub_category_name', e.target.value)}
                        placeholder="Enter category_name"
                    />
                </FormGroup>
            </Col>
            <Col md="6">
                <FormGroup>
                    <Label for="main_category">Select Main Category</Label>
                    <select
                        className="form-control"
                        value={editSub?.main_category_code || ''}
                        onChange={(e) => handleInputChange('main_category_code', e.target.value)}
                    >
                        <option value="">Select Main Category</option>
                        {MainCategoryDataInfo.map((category) => (
                            <option key={category.category_code} value={category.category_code}>
                                {category.category_name}
                            </option>
                        ))}
                    </select>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md="6">
                <FormGroup>
                    <Label for="newDescription">Description</Label>
                    <textarea
                        className="form-control"
                        value={editSub?.sub_category_description || ''}
                        onChange={(e) => handleInputChange('sub_category_description', e.target.value)}
                        placeholder="Enter category_description"
                    />
                </FormGroup>
            </Col>
            <Col md="6">
                <FormGroup>
                    <Label>Main Category Image</Label>
                    <input 
                        className="form-control" 
                        type="file" 
                        onChange={handleSubCategoryImageChange} 
                    />
                </FormGroup>
            </Col>
        </Row>
        
        <hr style={{ borderTop: '2px dashed #000' }} />

        <Row>
            <Col md="12">
                <FormGroup>
                    <Label>Description</Label>
                    <textarea className="form-control"
                        placeholder="Enter Description*"
                        value={marketData?.description || ''}
                        onChange={(e) => handleInputChangeNew('description', e.target.value)}
                    >
                    </textarea>
                </FormGroup>
            </Col>
        </Row>

        {/* <Row>
            <Col md="12">
                <FormGroup>
                    <Label>Banner Image</Label>
                    <div className={styles['banner-upload-wrapper']}>
                        {bannerImagePreview ? (
                            <img src={bannerImagePreview} alt="Banner Preview" />
                        ) : (
                            <div className={styles['banner-upload-placeholder']}>
                                <FiPlus className={styles['banner-upload-icon']} />
                                <span>Upload Image</span>
                            </div>
                        )}
                        <input
                            type="file"
                            onChange={handleBannerImageChange}
                        />
                    </div>
                </FormGroup>
            </Col>
        </Row> */}

        <Row>
            <Col md="6">
                <FormGroup>
                    <Label>Left Image</Label>
                    <div className={styles['banner-upload-wrapper']}>
                        {leftImage ? (
                            <img src={leftImage} alt="Uploaded Left" />
                        ) : (
                            <div className={styles['left-upload-placeholder']}>
                                <FiPlus className={styles['banner-upload-icon']} />
                                <span>Upload Image</span>
                            </div>
                        )}
                        <input
                            type="file"
                            className="form-control"
                            onChange={handleLeftImageChange}
                            style={{ opacity: 0, position: "absolute", width: "100%", height: "100%", cursor: "pointer" }}
                        />
                    </div>
                </FormGroup>
            </Col>
            <Col md="6">
                <FormGroup>
                    <Label>Right Image</Label>
                    <div className={styles['banner-upload-wrapper']}>
                        {rightImage ? (
                            <img src={rightImage} alt="Uploaded Left" />
                        ) : (
                            <div className={styles['left-upload-placeholder']}>
                                    <FiPlus className={styles['banner-upload-icon']} />
                                    <span>Upload Image</span>
                            </div>
                        )}
                        <input
                            type="file"
                            className="form-control"
                            onChange={handleRightImageChange}
                            style={{ opacity: 0, position: "absolute", width: "100%", height: "100%", cursor: "pointer" }}
                        />
                        </div>
                </FormGroup>
            </Col>

        </Row>
        <hr style={{ borderTop: '2px dashed #000' }} />
        <Row>
        <Col md="12">
                <Card className="p-4">
                    <div className={styles['custom-scrollbar']}>
                        {story_list.map((story, index) => (
                            <div key={index} className="mb-3">
                                <Row>
                                    <FormGroup>
                                        <h4>Content 1</h4>
                                        <Label>Description:</Label>
                                        <Editor
                                            value={story.story_description}
                                            style={{ height: '200px' }}
                                            onTextChange={(e) =>
                                                handleStoryInputChange(
                                                    index,
                                                    "story_description",
                                                    e.htmlValue || ""
                                                )
                                            }
                                        />
                                    </FormGroup>
                                </Row>
                            </div>
                        ))}
                    </div>
                </Card>
                <button
                    type="button"
                    onClick={addStory}
                    className="btn btn-primary mt-2 mb-2"
                >
                    + Add Content 1
                </button>
            </Col>
        </Row>
        <hr style={{ borderTop: '2px dashed #000' }} />
        <Row>
    {/*Story List*/}
        <Col md="12">
        <Card className="p-4">
            <div className={styles['custom-scrollbar']}>
            {data_list.map((story, index) => (
                <div key={index} className="mb-3">
                <Row>
                    <Col className="text-end">
                    <button
                        type="button"
                        onClick={() => removeStoryData(index, story._id, story.data_title, story.data_description)}
                        className="btn"
                    >
                        X
                    </button>
                    </Col>
                    <FormGroup>
                    <h4>Content 2</h4>
                    <Label>Content Title:</Label>
                    <input
                        className="form-control"
                        type="text"
                        value={story.data_title}
                        onChange={(e) =>
                            handleStoryDataInputChange(
                            index,
                            "data_title",
                            e.target.value
                        )
                        }
                        placeholder="Story title"
                    />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                    <Label>Content Description:</Label>
                    <Editor
                        value={story.data_description}
                        style={{ height: '200px' }}
                        onTextChange={(e) =>
                            handleStoryDataInputChange(
                            index,
                            "data_description",
                            e.htmlValue || ""
                        )
                        }
                    />
                    </FormGroup>
                </Row>
                </div>
            ))}
            </div>
        </Card>
        <button
            type="button"
            onClick={addStoryData}
            className="btn btn-primary mt-4 mb-2"
        >
            + Add Content 2
        </button>
        </Col>
        {/*Image*/}
        
    </Row>
    <Row>
        {/*FAQ List*/}
        <Col md="12">
        <Card className="p-4">
            <div className={styles['custom-scrollbar']}>
            {other_list.map((story, index) => (
                <div key={index} className="mb-3">
                <Row>
                    <Col className="text-end">
                    <button
                        type="button"
                        onClick={() => removeOtherListData(index)}
                        className="btn"
                    >
                        X
                    </button>
                    </Col>
                    <FormGroup>
                    <h4>Content 3</h4>
                    <Label>Content Title:</Label>
                    <input
                        className="form-control"
                        type="text"
                        value={story.other_title}
                        onChange={(e) =>
                            handleOtherListDataInputChange(
                            index,
                            "other_title",
                            e.target.value
                        )
                        }
                        placeholder="FAQ title"
                    />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                    <Label>Content Description:</Label>
                    <Editor
                        value={story.other_description}
                        style={{ height: '100px' }}
                        onTextChange={(e) =>
                            handleOtherListDataInputChange(
                            index,
                            "other_description",
                            e.htmlValue || ""
                        )
                        }
                    />
                    </FormGroup>
                </Row>
                </div>
            ))}
            </div>
        </Card>
        <button
            type="button"
            onClick={addOtherListData}
            className="btn btn-primary mt-4 mb-2"
        >
            + Add Content 3
        </button>
        </Col>
    </Row>
    </ModalBody>
    <ModalFooter>
        <Button color="primary" onClick={handleSave}>
            Save
        </Button>
        <Button color="secondary" onClick={() => setIsModalOpen(false)}>
            Cancel
        </Button>
    </ModalFooter>
</Modal>

            {/* Delete Confirmation Modal */}
            <Modal isOpen={confirmDelete} toggle={() => setConfirmDelete(false)}>
                <ModalHeader>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete this item?</ModalBody>
                <ModalFooter>
                <Button color="danger" onClick={() => confirmDeleteHandler(true)}>
                        Yes
                    </Button>
                    <Button color="secondary" onClick={() => setConfirmDelete(false)}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Edit Modal for MainCategory */}
            <Modal isOpen={isModalMainOpen} toggle={() => setIsModalMainOpen(!isModalMainOpen)}>
            <ModalHeader toggle={() => setIsModalMainOpen(!isModalMainOpen)}>Edit Item</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="category_name">Name</Label>
                    <input
                        type="text"
                        className="form-control"
                        value={editMain.category_name}  // Use editMain here
                        onChange={(e) => handleInputChangeMain('category_name', e.target.value)}
                        placeholder="Enter category_name"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="sub_description">Sub Description</Label>
                    <textarea
                        className="form-control"
                        value={editMain.sub_description}  // Use editMain here
                        onChange={(e) => handleInputChangeMain('sub_description', e.target.value)}
                        placeholder="Enter Small Description"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="Description">Description</Label>
                    <textarea
                        className="form-control"
                        value={editMain.Description}  // Use editMain here
                        onChange={(e) => handleInputChangeMain('Description', e.target.value)}
                        placeholder="Enter Description"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Main Category Image</Label>
                    <input 
                        className="form-control" 
                        type="file" 
                        onChange={handleFileChangePopupMain} 
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Category Image Icon</Label>
                    <input 
                        className="form-control" 
                        type="file" 
                        onChange={handleFileChangeIconPopup} 
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleEditMainCategory}>Save</Button>{' '}
                <Button color="secondary" onClick={() => setIsModalMainOpen(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>


            {/* Delete Confirmation Modal */}
            <Modal isOpen={confirmDeleteMain} toggle={() => setConfirmDeleteMain(false)}>
                <ModalHeader>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete this item?</ModalBody>
                <ModalFooter>
                <Button color="danger" onClick={() => confirmDeleteHandlerMain(true)}>
                        Yes
                    </Button>
                    <Button color="secondary" onClick={() => setConfirmDeleteMain(false)}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>

            {/* View Modal */}
            <Modal isOpen={isModalViewOpen} toggle={() => setIsModalViewOpen(!isModalViewOpen)} size="xl" style={{ maxWidth: '90%', width: '100%' }}>
            <ModalHeader toggle={() => setIsModalViewOpen(!isModalViewOpen)}>View</ModalHeader>
            <ModalBody>
                {marketViewData.map((categoryyy) => (
                    <div key={categoryyy._id}>
                        {/* Section 1: Banner Image with Text Overlay */}
                        <div 
                            style={{
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                height: '100px',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#000',
                                fontSize: '24px',
                                fontWeight: 'bold',
                                textShadow: '1px 1px 4px rgba(0, 0, 0, 0.6)'
                            }}
                        >
                            {/* <MyAzureBlobComponent imageUrl={categoryyy.banner_image} /> */}
                            {categoryyy.sub_category_name}
                        </div>

                        {/* Section 2: Description Paragraph */}
                        <div style={{ padding: '20px', fontSize: '16px', lineHeight: '1.5' }}>
                            <p dangerouslySetInnerHTML={{ __html: categoryyy.description }} />
                        </div>

                        {/* Section 3: 50% Left (Image) and 50% Right (Story List) */}
                        <Row style={{ padding: '20px' }}>
                            <Col md="6">
                            <img
                                src={categoryyy.left_image}
                                alt="Left Image"
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                                {/* <MyAzureBlobComponent imageUrl={categoryyy.left_image} /> */}
                            </Col>
                            <Col md="6">
                            <img
                                src={categoryyy.right_image}
                                alt="Right Image"
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                                {/* <MyAzureBlobComponent imageUrl={categoryyy.right_image} /> */}
                            </Col>
                            
                        </Row>
                        <Row style={{ padding: '20px' }}>
                        <Col md="12">
                                {categoryyy.story_list.map((story:any) => (
                                    <div key={story._id} style={{ marginBottom: '10px' }}>
                                        <p dangerouslySetInnerHTML={{ __html: story.story_description }} />
                                    </div>
                                ))}
                            </Col>
                        </Row>

                        {/* Section 4: 50% Left (Data List) and 50% Right (Right Image) */}
                        <Row style={{ padding: '20px' }}>
                            <Col md="12">
                                {categoryyy.data_list.map((data:any) => (
                                    <div key={data._id} style={{ marginBottom: '10px' }}>
                                        <h5>{data.data_title}</h5>
                                        <p dangerouslySetInnerHTML={{ __html: data.data_description }} />
                                    </div>
                                ))}
                            </Col>
                            
                        </Row >
                        <Row style={{ padding: '20px' }}>
                            <Col md="12">
                                {categoryyy.other_list.map((data:any) => (
                                    <div key={data._id} style={{ marginBottom: '10px' }}>
                                        <h5>{data.other_title}</h5>
                                        <p dangerouslySetInnerHTML={{ __html: data.other_description }} />
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </div>
                ))}
            </ModalBody>
        </Modal>


        </Col>
    );
}
