import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMainCategory,deleteMainCategory,createMainCategory,updateMainCategory,fetchMainCategoryCurrent,fetchMainCategoryByCode } from '../Apis/marketSolutions';
import { MainCategoryTableColumnData,subCategoryList, SubCategoryTableColumnData } from '../../Types/TableType';


interface MainCategoryResponse {
    _id:number,
    category_code:string,
    category_name:string,
    category_image:string,
    category_description:string,
    sub_description:string,
    category_icon:string,
    Description:string,
    sub_category_data:subCategoryList[]
}

interface State {
    data: {
        [key: string]: MainCategoryResponse; // Allow dynamic keys
    };
    main:{
        [key: string]:MainCategoryResponse;
    };
    byCode:{
        [key: string]: MainCategoryResponse;
    }
    isLoading: boolean;
    error: string | null; // Change type to include null
}
const initialState: State = {
    data: {},
    main:{},
    byCode:{},
    isLoading: false,
    error: null,
};

// Async thunk to fetch milestone data
export const fetchMainCategoryData = createAsyncThunk(
    'MainCategory/fetchMainCategoryData',
    async () => {
        const MainCategory = await fetchMainCategory();
        console.log("API response inside fetchMainCategoryData:", MainCategory); // Log the API response
        return MainCategory; // Return the nested MainCategory data
    }
);

export const fetchMainCategoryDataBycode = createAsyncThunk(
    'MainCategory/fetchMainCategoryDataBycode',
    async (category_code:string) => {
        const MainCategory = await fetchMainCategoryByCode(category_code);
        console.log("API response inside fetchMainCategoryData:", MainCategory); // Log the API response
        return MainCategory; // Return the nested MainCategory data
    }
);
export const fetchMainCategoryDataCurrent = createAsyncThunk(
    'MainCategory/fetchMainCategoryDataCurrent',
    async () => {
        const MainCategory = await fetchMainCategoryCurrent();
        console.log("API response inside fetchMainCategoryDataCurrent:", MainCategory); // Log the API response
        return MainCategory; // Return the nested MainCategory data
    }
);

export const deleteMainCategoryData = createAsyncThunk(
    "MainCategory/deleteMainCategoryData",
    async (MainCategory_code: string) => {
        const data = await deleteMainCategory(MainCategory_code);
        return data;
    }
);

// MainCategoryReducer.tsx
export const createMainCategoryData = createAsyncThunk(
    "MainCategory/createMainCategoryData",
    async (formData: FormData) => { // Expect FormData as the input
        const data = await createMainCategory(formData); // Pass it directly to createMainCategory
        return data;
    }
);


export const updateMainCategoryData = createAsyncThunk(
    "MainCategory/updateMainCategoryData",
    async (formData: FormData) => {
        const data = await updateMainCategory(formData); 
        return data;
    }
);

const MainCategorySlice = createSlice({
    name: 'MainCategory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMainCategoryData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchMainCategoryData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchMainCategoryData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            .addCase(fetchMainCategoryDataBycode.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchMainCategoryDataBycode.fulfilled, (state, action) => {
                state.isLoading = false;
                state.byCode = action.payload.data;
            })
            .addCase(fetchMainCategoryDataBycode.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            .addCase(fetchMainCategoryDataCurrent.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchMainCategoryDataCurrent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.main = action.payload.data;
            })
            .addCase(fetchMainCategoryDataCurrent.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            .addCase(createMainCategoryData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createMainCategoryData.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(createMainCategoryData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            .addCase(updateMainCategoryData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateMainCategoryData.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(updateMainCategoryData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            .addCase(deleteMainCategoryData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteMainCategoryData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            
    },
});

export default MainCategorySlice.reducer;

