import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { RootState, AppDispatch } from '../../../../../redux-toolkit/store';
import { TableColumn } from "react-data-table-component";
import CardHeaderSpan from '../../../../../Utils/CommonComponents/CardHeaderSpan';
import { TechnologyTitle } from '../../../../../Utils/Constants';
import FilterComponent from '../../../../Tables/DataTables/Common/FilterComponent';
import { fetchTechnologiesData, deleteTechnologyData, updateTechnologyData } from '../../../../../redux-toolkit/reducers/TechnologyReducer';

export interface TechnologyFormData {

    technologyCode: string; // Technology code
    technologyname: string; // Technology name
    technology_image: string;
    technologyImg:string;
    
}

export default function TechnologyManagement() {
    const [filterText, setFilterText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTechnology, setSelectedTechnology] = useState<TechnologyFormData | null>(null);
    const [editData, setEditData] = useState<TechnologyFormData>({
        technologyCode: '',
        technologyname: '',
        technology_image:"",
        technologyImg: "",
    });
    const dispatch: AppDispatch = useDispatch();

    const technologies = useSelector((state: RootState) => state.technology);
    const technologiesData: TechnologyFormData[] = Array.isArray(technologies?.data)
        ? technologies.data
        : Object.values(technologies?.data || {});

    useEffect(() => {
        const fetchTechnologies = async () => {
            try {
                await dispatch(fetchTechnologiesData());
                console.log("Fetched technologies data:", technologiesData);
            } catch (error) {
                console.error("Error fetching technologies:", error);
            }
        };

        fetchTechnologies();
    }, [dispatch]);

    const filteredItems: TechnologyFormData[] = technologiesData.filter(
        (item) =>
            Object.values(item).some((value) =>
                value && value?.toString().toLowerCase().includes(filterText.toLowerCase())
            )
    );

    const 
    handleEdit = (row: TechnologyFormData) => {
        console.log("Editing row:", row);
        if (row && row.technologyCode) {
            setEditData(row);
            setSelectedTechnology(row);
            setIsModalOpen(true);
        } else {
            console.error("Selected row does not contain technologyCode", row);
        }
    };

    const handleDelete = (row: TechnologyFormData) => {
        dispatch(deleteTechnologyData(row.technologyCode))
            .then(() => {
                dispatch(fetchTechnologiesData());
            })
            .catch((error) => {
                console.error("Failed to delete:", error);
            });
    };

    const handleModalChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setEditData({
                ...editData,
                technology_image: URL.createObjectURL(file), // Display preview
            });
        }
    };

    const handleSubmit = async () => {
        try {
            await dispatch(updateTechnologyData(editData));
            await dispatch(fetchTechnologiesData());
            setIsModalOpen(false);
        } catch (error) {
            console.error("Failed to update technology:", error);
        }
    };

    const rowTechnologyTableColumns: TableColumn<TechnologyFormData>[] = [
        {
            name: "Technology Name",
            selector: (row) => row.technologyname,
            sortable: true,
        },
        {
            name: "Technology Image",
            selector: (row) => row.technology_image,
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        onClick={() => handleEdit(row)}
                        color="primary"
                        size="sm"
                        style={{
                            width: '100px',
                            margin: '3px',
                            borderRadius: '4px',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        onClick={() => handleDelete(row)}
                        color="danger"
                        size="sm"
                        style={{
                            width: '100px',
                            margin: '3px',
                            borderRadius: '4px',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <Col sm={12}>
            <Card>
                <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={TechnologyTitle} />
                <CardBody>
                    <FilterComponent
                        onFilter={(e) => setFilterText(e.target.value)}
                        onClear={() => setFilterText('')}
                        filterText={filterText}
                    />
                    <div className="table-responsive custom-scrollbar">
                        <DataTable
                            data={filteredItems}
                            columns={rowTechnologyTableColumns}
                            striped
                            pagination
                            className="display dataTable"
                        />
                    </div>
                </CardBody>
            </Card>

            {/* Edit Modal */}
            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
                <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Technology</ModalHeader>
                <ModalBody>
                    <form>
                        <FormGroup>
                            <Label for="technologyname">Technology Name</Label>
                            <Input
                                type="text"
                                name="technologyname"
                                value={editData.technologyname}
                                onChange={handleModalChange}
                            />
                        </FormGroup>
                        <FormGroup>
                                <Label for="Client_icon">Technology Image</Label>
                                <Input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={handleImageChange} 
                                    style={{ marginTop: "10px" }} 
                                />
                                <img 
                                    src={editData.technologyImg} 
                                    alt={editData.technologyname} 
                                    style={{ width: "100px", height: "100px", marginTop: "10px" }}
                                />
                            </FormGroup>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>{' '}
                    <Button color="secondary" onClick={() => setIsModalOpen(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Col>
    );
}
